import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

export default function UpdateNote() {
  const [note, setNote] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchNoteById() {
      try {
        const response = await axios.get(`https://ambitionstudies-server.vercel.app/notes/${id}`);
        setNote(response.data);
      } catch (error) {
        console.error('Error fetching note:', error.message);
      }
    }

    fetchNoteById();
  }, [id]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.put(`https://ambitionstudies-server.vercel.app/notes/${id}`, {
        title: note.title,
        content: note.content,
      });
      // Redirect or perform any other action after successful update
      navigate(`/notes/${id}`);
    } catch (error) {
      console.error('Error updating note:', error.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNote((prevNote) => ({ ...prevNote, [name]: value }));
  };

  return (
    <div className='upload-form'>
      <h1>Update Note</h1>
      {note && (
        <form onSubmit={handleFormSubmit}>
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            name="title"
            value={note.title}
            onChange={handleInputChange}
          />
          <label htmlFor="content">Content</label>
          <textarea
            id="content"
            name="content"
            value={note.content}
            onChange={handleInputChange}
            style={{'minHeight': '25rem'}}
          ></textarea>
          <button type="submit">Submit</button>
        </form>
      )}
    </div>
  );
}
