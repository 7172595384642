import React, {useState, useEffect} from 'react'
export default function Users() {
    const [users, setUsers] = useState(null);
    useEffect(() => {
        const token = localStorage.getItem('token');
    
        if (!token) {
          console.error('Token not available');
          return;
        }
    
        fetch('https://ambitionstudies-server.vercel.app/users', {
          method: 'GET',
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
          },
        })
          .then(response => response.json())
          .then(data => setUsers(data))
          .catch(error => console.error('Error:', error));
      }, []); 
    
  return (
    <div>
        <h1>Users</h1>
        <p>Total Users: {users?.length}</p>
        <p>{users && users.map((item) => {
            return <p>{item}</p>
        })}</p>
    </div>
  )
}
