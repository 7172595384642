import React from 'react'
import '../css/Notes.css'

export default function Error() {
    return (
        <div className="not-available-container">
            <div className="not-available-content">
                <h2>Guess You Got Lost! Page Not Found!</h2>
                <img src='images/error.jpg'></img>
            </div>
        </div>
    )
}
