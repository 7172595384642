import React from 'react'
import '../css/Classwise.css'

export default function Nineth() {
  return (
    <div>
        <h2 class="heading">9th Class Notes</h2>
    <div class="subjects">
        <div class="subject">
            <h1>Science</h1>
            <div class="chapter">
                <a class="pdf-link" href="notes/nutition-in-plant">Chapter 1: Nutrition In Plants</a>
                
                
            </div>
        </div>
    
        <div class="subject">
            <h1>Maths</h1>
            <div class="chapter">
                <a class="pdf-link" href="notes/integers">Chapter 1: Integers</a>
                
            </div>
        </div>
    </div>
    </div>
  )
}
