import React from 'react';
import '../css/Home.css'

export default function Home() {
    const user = JSON.parse(localStorage.getItem('user'));
    return (
        <div>
            <div id='marquee'>
                <marquee behavior="scroll" direction="left" scrollamount="10">
                    <span style={{marginRight: '200px'}}>Welcome to ambitionstudies.com - Unlocking Knowledge!</span>
                    <span style={{marginRight: '200px'}}>Discover a World of Learning at ambitionstudies.com</span>
                    <span>Education Elevated: ambitionstudies.com -  Where Learning Takes Flight</span>
                </marquee>
            </div>
            <div id="home">
                <div className="image-carousel">
                    <img src='images/image1.jpg' />
                </div>

                <p className='hi'>{user ? "Hi, " + user.username : ""}</p>
                <section id="links">
                    <h2>Material Links</h2>
                    <div className="links-grid">
                        <ul>
                            <li><a href="/">Syllabus</a></li>
                            <li><a href="/">Sample papers</a></li>
                            <li><a href="/">Ncert Solutions</a></li>
                        </ul>
                        <ul>
                            <li><a href="/">G-form tests</a></li>
                            <li><a href="/">Live worksheets</a></li>
                            <li><a href="/">Certificate tests</a></li>
                        </ul>
                        <ul>
                            <li><a href="/">Mock tests</a></li>
                            <li><a href="/">Sample paper solutions</a></li>
                        </ul>
                    </div>
                    <br />
                    <br />
                    <p>
                        <b>Necessary guidance:</b> Download latest CBSE and NCERT based study
                        material for you classes and subjects. Click on the link of your classes
                        and access free subject wise NCERT solutions, solved sample papers, last
                        year question papers, easy to learn notes and formula, download in pdfs.
                        For quick learning, the study material has been prepared based on the
                        latest syllabus by the best teacher faculty in India.
                    </p>
                </section>


            </div>
        </div>
    )
}
