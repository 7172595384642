import React from 'react'

export default function Worksheets() {
  return (
    <div id='notes'>
            <h1>Worksheets</h1>
            <div class="classes">
                <div class="class-image">
                    <a href="/6worksheets">
                        <img src="images/image6.jpeg" alt="" class="class-img" />
                        <button class="class">6th</button>
                    </a>
                </div>
                <div class="class-image">
                    <a href="/7worksheets">
                        <img src="images/image8.jpeg" alt="" class="class-img" />
                        <button class="class">7th</button>
                    </a>
                </div>
                <div class="class-image">
                    <a href="/8worksheets">
                        <img src="images/image9.jpeg" alt="" class="class-img" />
                        <button class="class">8th</button>
                    </a>
                </div>
                <div class="class-image">
                    <a href="/9worksheets">
                        <img src="images/image10.jpeg" alt="" class="class-img" />
                        <button class="class">9th</button>
                    </a>
                </div>
                <div class="class-image">
                    <a href="/10worksheets">
                        <img src="images/image11.jpeg" alt="" class="class-img" />
                        <button class="class">10th</button>
                    </a>
                </div>
                {/* <div class="class-image">
                    <a href="">
                        <img src="images/image12.jpeg" alt="" class="class-img" />
                        <button class="class">11th</button>
                    </a>
                </div>
                <div class="class-image">
                    <a href="" >
                        <img src="images/image7.jpeg" alt="" class="class-img" />
                        <button class="class">12th</button>
                    </a>
                </div> */}
            </div>
        </div>
  )
}
