import React from 'react'
import '../css/Classwise.css'

export default function SeventhWorksheets() {
    return (
        <div>
            <h2 class="heading">7th Class Worksheets</h2>
            <div class="subjects">
                <div class="subject">
                    <h1>Science</h1>
                    <div class="chapter">
                        <a class="pdf-link" href="crops-production-and-mangement">Worksheet 1</a>
                        <a class="pdf-link" href="microorganism-friends-and-foe">Worksheet 2</a>
                    </div>
                </div>
                <div class="subject">
                    <h1>Maths</h1>
                    <div class="chapter">
                        <a class="pdf-link" href="crops-production-and-mangement">Worksheet 1</a>
                        <a class="pdf-link" href="microorganism-friends-and-foe">Worksheet 2</a>
                    </div>
                </div>
                <div class="subject">
                    <h1>Social Studies</h1>
                    <div class="chapter">
                        <a class="pdf-link" href="crops-production-and-mangement">Worksheet 1</a>
                        <a class="pdf-link" href="microorganism-friends-and-foe">Worksheet 2</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
