import React from 'react'
import '../css/Classwise.css'

export default function Seventh() {
    
    return (
        <div>
            <h2 class="heading">7th Class Notes</h2>
            <div class="subjects">
                <div class="subject">
                    <h1>Science</h1>
                    <div class="chapter">
                        <a class="pdf-link" href="nutrition-in-plant">Chapter 1: Nutrition in plants</a>
                        <a class="pdf-link" href="nutrition-in-animals">Chapter 2: Nutrition in animals</a>
                        <a class="pdf-link" href="fibre-to-fabric">Chapter 3: Fibre to fabric</a>
                        <a class="pdf-link" href="acids-base-and-salts">Chapter 4: Acids, Bases and salt</a>
                        <a class="pdf-link" href="physicsal-and-chemical-change">Chapter 5: Physical and Chemicval Change</a>
                        <a class="pdf-link" href="respiration-in-organisms">Chapter 6: Respiration in Organisms</a>
                        <a class="pdf-link" href="transporation-in-animals-and-plants">Chapter 7:Transporation in Animlas and Plants </a>
                        <a class="pdf-link" href="reproduction-in-plnats">Chapter 8: Reproduction in PLants</a>
                        <a class="pdf-link" href="motion-and-time">Chapter 9: Motion and Time</a>
                        <a class="pdf-link" href="electric-current-and-its-effects">Chapter 10: Electric current and its effects</a>
                        <a class="pdf-link" href="light">Chapter 11: Light</a>
                        <a class="pdf-link" href="forests-our-lifelines">Chapter 12: Forests - Our Lifeline</a>
                        <a class="pdf-link" href="wastewater-story">Chapter 13: Wastewater story</a>
                    </div>
                </div>
                <div class="subject">
                    <h1>Social Studies</h1>
                    <div class="chapter">
                        <a class="pdf-link" href="nutition-in-plant">Chapter 1: Nutrition in plants</a>
                        <a class="pdf-link" href="nutition-in-animal">Chapter 2: Nutrition in animals</a>
                        <a class="pdf-link" href="fibre-to-fabric">Chapter 3: Fibre to fabric</a>
                        <a class="pdf-link" href="acids-base-and-salts">Chapter 4: Acids, Bases and salt</a>
                        <a class="pdf-link" href="physicsal-and-chemical-change">Chapter 5: Physical and Chemicval Change</a>
                        <a class="pdf-link" href="respiration-in-organisms">Chapter 6: Respiration in Organisms</a>
                        <a class="pdf-link" href="transporation-in-animals-and-plants">Chapter 7:Transporation in Animlas and Plants </a>
                        <a class="pdf-link" href="reproduction-in-plnats">Chapter 8: Reproduction in PLants</a>
                        <a class="pdf-link" href="motion-and-time">Chapter 9: Motion and Time</a>
                        <a class="pdf-link" href="electric-current-and-its-effects">Chapter 10: Electric current and its effects</a>
                        <a class="pdf-link" href="light">Chapter 11: Light</a>
                        <a class="pdf-link" href="forests-our-lifelines">Chapter 12: Forests - Our Lifeline</a>
                        <a class="pdf-link" href="wastewater-story">Chapter 13: Wastewater story</a>
                    </div>
                </div>

                <div class="subject">
                    <h1>Maths</h1>
                    <div class="chapter">
                        <a class="pdf-link" href="integers">Chapter 1: Integers</a>
                        <a class="pdf-link" href="fractions-and-decimals">Chapter 2: Fractions and Decimals</a>
                        <a class="pdf-link" href="acids-bases-and-salt">Chapter 2: Acids,Bases and salt</a>
                        <a class="pdf-link" href="data-handling">Chapter 3: Data Handling</a>
                        <a class="pdf-link" href="simple-equations">Chapter 4: Simple Equations</a>
                        <a class="pdf-link" href="lines-and-angles">Chapter 5: Lines and Angles</a>
                        <a class="pdf-link" href="the-triangle-and-its-properties">Chapter 6: The Triangle and its Properties</a>
                        <a class="pdf-link" href="congruency-of-triangles">Chapter 7: Congruence of Triangles</a>
                        <a class="pdf-link" href="comparing-of-triangles">Chapter 8: Comparing of Triangles</a>
                        <a class="pdf-link" href="rational-numbers">Chapter 9: Rational Numbers</a>
                        <a class="pdf-link" href="practical-geometry">Chapter 10: Practical Geometry</a>
                        <a class="pdf-link" href="perimeter-and-area">Chapter 11: Perimeter and Area</a>
                        <a class="pdf-link" href="algerbric-expressions">Chapter 12: Algebric Expressions</a>
                        <a class="pdf-link" href="exponents-and-powers">Chapter 13: Exponents and Powers</a>
                        <a class="pdf-link" href="symmetry">Chapter 14: Symmetry</a>
                        <a class="pdf-link" href="visualising-solid-shapes">Chapter 15: Visualising Solid Shapes</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
