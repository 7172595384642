import { Outlet } from 'react-router-dom';
import './css/Layout.css';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

const Layout = () => {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const handleLogout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        navigate('/');
        window.location.reload();
    }

    return (
        <div className='full'>
            <nav>
                <div className='nav-inside'>
                    <div id="logo"><a href='/'><img src='images/as_logo.png' className='logo-img' /></a></div>
                    <ul className={`menu ${isMenuOpen ? 'active' : ''}`}>
                        <li><a href="/">Home</a></li>
                        <li><a href="#contact">Contact</a></li>
                        <li><a href="/tests">Online Tests</a></li>
                        <li><a href="/notes">Notes</a></li>
                        <li><a href="/worksheets">Worksheets</a></li>
                        {user?.role === 'whatsthereason' ? <li><a href="/admin">Admin</a></li> : null}
                        {user ? <li><a onClick={handleLogout} style={{cursor:'pointer'}}>Logout</a></li> : <li><a href="/auth" >Login</a></li>}
                    </ul>
                    <div id="menu-toggle" onClick={toggleMenu}>
                        <div className={`bar ${isMenuOpen ? 'active' : ''}`}></div>
                        <div className={`bar ${isMenuOpen ? 'active' : ''}`}></div>
                        <div className={`bar ${isMenuOpen ? 'active' : ''}`}></div>
                    </div>
                </div>
            </nav>
            <div className="body">
                <Outlet />
            </div>
            <div className='contact-container'>
                <section id="contact">
                    <h2>Contact</h2>
                    <br />
                    <div class="contact-form">
                        <div id="form">
                            <form action="https://formspree.io/f/mrgwvapb" method="POST">
                                <div class="input">
                                    <label>Name </label>
                                    <input type="text" name=" name " autoComplete='off' />
                                </div>
                                <br />

                                <div className="input">
                                    <label>Phone </label>
                                    <input type="number" name=" Number" autoComplete='off' />
                                </div>
                                <br />

                                <div className="input">
                                    <label>E-mail </label>
                                    <input type="email" name=" Email " autoComplete='off' />
                                </div>
                                <br />

                                <div className="input">
                                    <label>Message </label>
                                    <input type="text" name=" message " autoComplete='off' />
                                </div>
                                <br />

                                <button type="submit" className="button">Submit</button>
                            </form>
                        </div>

                        <div id="details">
                            <h3>Contact Info</h3>
                            <br />
                            <div>
                                <div class="contact-logo">
                                    <img src="images/whatsapp.png" alt="" />
                                    <p><a href="tel:9821484397">9821484397</a></p>
                                </div>
                                <div class="contact-logo">
                                    <img src="images/gmail.png" alt="" />
                                    <p><a href="mailto:aadrshgupta6@gmail.com">aadrshgupta6@gmail.com</a></p>
                                </div>
                                <div class="contact-logo">
                                    <img src="images/instagram.png" alt="" />
                                    <p>
                                        <a
                                            href="https://www.instagram.com/aadrshgupta6/"
                                            target="_blank" rel="noreferrer"
                                        >aadrshgupta6</a
                                        >
                                    </p>
                                </div>
                                <div className="contact-logo">
                                    <img src="images/placeholder.png" alt="" />
                                    <p>
                                        Gali no. 6, Gandhi Nagar, Near Hanuman Temple, Gurugram,
                                        Haryana, 122001
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <footer>
                <div>
                    <p>AmbitionStudies © 2024</p>
                    <br></br>
                    <p>Aadrsh Gupta</p>
                </div>
                <div>
                    <p>Passionate Education</p>
                    <br></br>
                    Website by Kashish Biserwal
                </div>
            </footer>
        </div>
    );
};

export default Layout;
