import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Error from './pages/Error'
import Layout from "./Layout";
import Home from "./pages/Home";
import Tests from "./pages/Tests";
import Notes from "./pages/Notes";
import Tenth from "./pages/Tenth";
import Seventh from "./pages/Seventh";
import LoginSignupPage from "./pages/LoginSignupPage";
import Admin from "./pages/Admin";
import Note from "./pages/Note";
import UploadNote from "./pages/UploadNote";
import UpdateNote from "./pages/UpdateNote";
import NotAvailable from "./pages/NotAvailable";
import Users from "./pages/Users";
import Eighth from "./pages/Eighth";
import Nineth from "./pages/Nineth";
import Sixth from "./pages/Sixth";
import Worksheets from "./pages/Worksheets";
import SixthWorksheets from "./pages/SixthWorksheets";
import SeventhWorksheets from "./pages/SeventhWorksheets";
import EighthWorksheets from "./pages/EighthWorksheets";
import NinethWorksheets from "./pages/NinethWorksheets";
import TenthWorksheets from "./pages/TenthWorksheets";

function App() {
  const role = JSON.parse(localStorage.getItem("user"))?.role;
  const isAdmin = role === "whatsthereason";
  return (
    <>
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="tests" element={<Tests />}/>
          <Route path="worksheets" element={<Worksheets />}/>
          {isAdmin ? <Route path="admin" element={<Admin />}/> : <Route path="admin" element={<Error />}/>}
          {isAdmin ? <Route path="users" element={<Users />}/> : <Route path="users" element={<Error />}/>}
          {isAdmin ? <Route path="uploadNote" element={<UploadNote />}/> : <Route path="uploadNote" element={<Error />}/>}
          {isAdmin ? <Route path="updateNote/:id" element={<UpdateNote />}/> : <Route path="updateNote/:id" element={<Error />}/>}
          <Route path="notes" element={<Notes />}/>
          <Route path="6" element={<Sixth />}/>
          <Route path="6worksheets" element={<SixthWorksheets />}/>
          <Route path="7worksheets" element={<SeventhWorksheets />}/>
          <Route path="8worksheets" element={<EighthWorksheets />}/>
          <Route path="9worksheets" element={<NinethWorksheets />}/>
          <Route path="10worksheets" element={<TenthWorksheets />}/>
          <Route path="7" element={<Seventh />}/>
          <Route path="8" element={<Eighth/>}/>
          <Route path="9" element={<Nineth/>}/>
          <Route path=":id" element={<Note />}/>
          <Route path="10" element={<Tenth />}/>
          <Route path="/notAvailable" element={<NotAvailable />}/>
          <Route path="auth" element={<LoginSignupPage />} />

          <Route path="*" element={<Error />}/>
        </Route>
      </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
