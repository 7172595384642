import React from 'react'
import '../css/Notes.css'

export default function NotAvailable() {
    return (
        <div className="not-available-container">
            <div className="not-available-content">
                <h2>Sorry, this page is not available yet.</h2>
                <img src='images/unavailable.jpg'></img>
            </div>
        </div>
    )
}
