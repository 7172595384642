import React, { useEffect, useState } from 'react';
// import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import "../css/Note.css"
// import DOMPurify from 'dompurify';
// import Loading from './Loading';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export default function Note() {
  // const user = JSON.parse(localStorage.getItem('user'));
  // const [note, setNote] = useState(null);
  // const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const pdfUrl = `https://ambitionstudies.com/pdfs/${id}.pdf`;  

  // ===================
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const renderPages = () => {
    const pages = [];
    for (let i = 1; i <= numPages; i++) {
      pages.push(
        <div key={i}>
          <Page
            key={i}
            pageNumber={i}
            renderTextLayer={false}
          />
        </div>
      );
    }

    return pages;
  };


  // async function fetchNoteById(id) {
  //   try {
  //     const response = await axios.get(`https://ambitionstudies-server.vercel.app/notes/${id}`);
  //     setNote(response.data);
  //   } catch (error) {
  //     console.error('Error fetching note:', error.message);
  //     navigate('/notAvailable');
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  // useEffect(() => {
  //   fetchNoteById(id);
  // }, [id]);

  // const sanitizedHTML = DOMPurify.sanitize(note?.content || '');

  // if (loading) {
  //   return <Loading />;
  // }

  return (
    <div>
      {/* <h1>{note?.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
      {user?.role === 'whatsthereason' ? (
        <a href={`/updateNote/${id}`}>
          <button className='btn'>Update</button>
        </a>
      ) : null} */}

      <div style={{ width: '100%' }} className='pdf'>
        <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
          {renderPages()}
        </Document>
      </div>
    </div>

  );
}
