import React, {useEffect} from 'react'

export default function Admin() {
  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      console.error('Token not available');
      return;
    }

    fetch('https://ambitionstudies-server.vercel.app/admin-only', {
      method: 'GET',
      headers: {
        'Authorization': `${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error('Error:', error));
  }, []); 
  return (
    <div>
      <h1>Welcome, Admin</h1>
      <a href='/uploadNote'><button className='btn'>Upload Note</button></a>
      <a href='/users'><button className='btn'>View Users</button></a>
    </div>
  )
}
