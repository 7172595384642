import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../css/LoginSignupPage.css';

function LoginSignupPage() {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(true);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const toggleForm = (e) => {
    e.preventDefault();
    setIsLogin(!isLogin);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isLogin) {
      try {
        const response = await axios.post('https://ambitionstudies-server.vercel.app/login', {
          username,
          password,
        });
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        navigate('/');

      } catch (error) {
        setError('Login failed. Check your credentials.');
      }
    } else {
      if (password !== confirmPassword) {
        setError('Passwords do not match');
        return;
      }

      try {
        const response = await axios.post('https://ambitionstudies-server.vercel.app/signup', {
          username,
          password,
        });
        alert('Sign-up successful, Please Login.');
      } catch (error) {
        setError('Username already exists. Please try again.');
      }
    }
  };

  return (
    <div className="login-signup-container">
      <h2>{isLogin ? 'Log In' : 'Sign Up'}</h2>
      <form className="login-signup-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Username</label>
          <input
            type="text"
            placeholder="Your username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            placeholder="Your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {!isLogin && (
          <div className="form-group">
            <label>Confirm Password</label>
            <input
              type="password"
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
        )}
        <button type="submit">{isLogin ? 'Log In' : 'Sign Up'}</button>
      </form>
      {error && <p className="error-message">{error}</p>}
      <br />
      <p onClick={toggleForm}>
        {isLogin ? "Don't have an account? Sign Up" : 'Already have an account? Log In'}
      </p>
    </div>
  );
}

export default LoginSignupPage;
