import React from 'react'
import '../css/Classwise.css'

export default function Tenth() {
  return (
    <div>
      <h2 class="heading">10th Class Notes</h2>
      <div class="subjects">
        <div class="subject">
          <h1>Science</h1>
          <div class="chapter">
            <a class="pdf-link" href="notes/crops-production-and-mangement">Chapter 1: Crops Production and Management</a>
            <a class="pdf-link" href="notes/microorganism-friends-and-foe">Chapter 2: Microorganism: Friends and Foe</a>


          </div>
        </div>

        <div class="subject">
          <h1>Maths</h1>
          <div class="chapter">
            <a class="pdf-link" href="notes/integers">Chapter 1: Integers</a>

          </div>
        </div>
      </div>
    </div>
  )
}
