import React, { useState } from 'react'
import "../css/Notes.css"

export default function UploadNote() {
    const token = localStorage.getItem('token');
    const [formData, setFormData] = useState({
        slug: '',
        title: '',
        content: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleUploadSubmit = async (e) => {
        e.preventDefault();
        fetch('https://ambitionstudies-server.vercel.app/notes', {
            method: 'POST',
            headers: {
                'Authorization': `${token}`,
                'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.error('Error:', error));
            alert("Note Uploaded Successfully")
    };
    return (
        <div className='upload-form'>
            <form onSubmit={handleUploadSubmit}>
                <label htmlFor="slug">Page Name:</label>
                <input
                    type="text"
                    id="slug"
                    name="slug"
                    value={formData.slug}
                    onChange={handleChange}
                    required
                />

                <label htmlFor="title">Title:</label>
                <input
                    type="text"
                    id="title"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    required
                />

                <label htmlFor="content">Content:</label>
                <textarea
                    id="content"
                    name="content"
                    rows="8"
                    value={formData.content}
                    onChange={handleChange}
                    style={{'minHeight': '20rem'}}
                    required
                ></textarea>

                <button type="submit">Submit</button>
            </form>
        </div>
    )
}
